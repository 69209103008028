import React, {useState} from "react"
import emailjs from 'emailjs-com';
import { SpinnerCircular } from 'spinners-react';
import "spinners-react/lib/SpinnerCircular.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => {

    const [from_name, setName] = useState('');
    const [from_email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [how_know_about_us, setHowKnowAboutUs] = useState('');
    const [message, setMessage] = useState('');

    const [showLoader, setShowLoader] = useState(false);
    const [showSuccessmesage, setShowSuccessmesage] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        setShowLoader(true);

        let YOUR_SERVICE_ID = process.env.EMAILJS_SERVICE_ID;
        let YOUR_TEMPLATE_ID = process.env.EMAILJS_CONTACT_TEMPLATE_ID;
        let YOUR_USER_ID = process.env.EMAILJS_USER_ID;
    
        emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, e.target, YOUR_USER_ID)
        .then((result) => {
            //console.log(result.text);
            setShowLoader(false);
            if(result.text == 'OK'){
                setShowSuccessmesage(true);
                setName('');
                setEmail('');
                setCompany('');
                setHowKnowAboutUs('');
                setMessage('');

                setTimeout(()=>{
                    setShowSuccessmesage(false);
                }, 3000)
            }
        }, (error) => {
            console.log(error.text);
        });
    }

    return (
    <Layout>
    <SEO title="Contact Us" />
    <div className="contentWrapper">
        <section className="innerSection">
            <div className="wrapper">
                <h2 className="mainHeading">Let's Start a Conversation</h2>
                <p className="subHead">Got a question or project? Contact us below.</p>
                <div className="contactBlkWrap pageContactWrap">
                    <form onSubmit={sendEmail}>
                        <div className="rowBlk">
                            <div className="fldWrap">
                                <label for="name">Name</label>
                                <input type="text" id="from_name" name="from_name" onChange={e => setName(e.target.value)} value={from_name} placeholder="Name *" required />
                            </div>
                            <div className="fldWrap">
                                <label for="email">Email</label>
                                <input type="email" id="from_email" name="from_email" onChange={e => setEmail(e.target.value)} value={from_email} placeholder="Email *" required />
                            </div>
                        </div>
                        <div className="rowBlk">
                            <div className="fldWrap">
                                <label for="company">Company</label>
                                <input type="text" id="company" name="company" onChange={e => setCompany(e.target.value)} value={company} placeholder="Company" />
                            </div>
                            <div className="fldWrap">
                                <label for="how_know_about_us">How did you hear about us?</label>
                                <select id="how_know_about_us" name="how_know_about_us" onChange={e => setHowKnowAboutUs(e.target.value)} value={how_know_about_us}>
                                    <option>How did you hear about us?</option>
                                    <option value="Internet Search">Internet Search</option>
                                    <option value="Referral">Referral</option>
                                    <option value="Social Platforms">Social Platforms</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="fldWrap">
                            <label for="message">Your question or something about your project... </label>
                            <textarea id="message" name="message" onChange={e => setMessage(e.target.value)} value={message} placeholder="Your question or something about your project * " required ></textarea>
                        </div>
                        <p>By clicking Submit, you agree to the Digital Avenues <a target="_blank" href="/privacy-policy">Privacy Policy</a>.</p>
                        <button type="submit" className="btnStyle1">
                            {!showLoader &&
                                <span>Submit</span>
                            }
                            {showLoader &&
                                <SpinnerCircular size={28} thickness={144} speed={173} color="rgba(57, 172, 129, 1)" secondaryColor="rgba(0, 0, 0, 1)" />
                            }
                        </button>

                        {showSuccessmesage &&
                            <div className="successMsg mt16">Thank you for getting in touch! </div>
                        }
                        
                    </form>
                </div>
                <div className="mapWrap noContact">
                    <img src="//res.cloudinary.com/digital-avenues/image/upload/v1616559315/digitalavenues.com/static/map.png" alt="" />
                    {/* <div className="contactInfo">
                        <div><a href="mailto:biz@digitalavenues.com" className="contactEmail">biz@digitalavenues.com</a></div>
                        <div><a href="tel:+91.33.4006 0404" className="contactPhone">+91.33.4006 0404</a></div>    
                    </div> */}
                </div>
            </div>
        </section>
    </div>
  </Layout>
  )
  

}

export default SecondPage
